import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Gemini.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Gemini Facts
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/gemini"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Gemini</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Gemini Facts</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Gemini Facts</h1>
                <h2 className="text-sm md:text-base ml-4">May 21 - Jun 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/gimini/gimini-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Gemini Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-love");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-nature");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-man");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-traits");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/gemini/gemini-facts");
                  }}>
                  <h5 className="font-semibold text-black">Gemini Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
             
            <p className="playfair text-black text-xl md:text-3xl">Gemini Facts</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Gemini Facts:</p>clever, rebellious, sarcastic, independent, spontaneous, visionary, adventurous, unique, down to earth, honest, loyal, mind reader
 <br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Gemini Problem:</p>You won't ask twice. You are multi-faceted and ever-changing. This makes it hard to pin down your emotions, confusing you. You feel things deeply and then often question your feelings.
<br/><br/> 
 
Gemini in their lowest point: you may find yourself feeling lost and alone. You may think that you're dreaming or someone has walked into your space and stolen your words. You may find yourself staring off into space or lost in your thoughts. <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Gemini motto:</p> I am simple with a complicated mind of the person.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">The evil side to a Gemini: </p>You love to get under other people's skins, just for its fun. You're so good at tormenting others that it's a big part of your life.
 <br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Things Gemini hates:</p> You're a hard worker. You believe that you can get things done independently, without any help. You hate being told what to do. You want to be in charge of your life and schedule, not someone else's.
 <br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Things Gemini do when pissed off: </p>When Gemini gets angry, they can get loud and lose their temper. They will not yell and use profanity, but they may act rude and distant. They will not want to deal with other people and want to be alone, but they will not stay mad for long. 
 <br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Things Gemini do when doubtful:</p> When the doubt sets in, they'll sit quietly and keep on overthinking. It allows them to conclude on their own, without outside influence.
 <br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Assumptions about a Gemini:</p> You're a Gemini, and there's a lot you didn't know about your sign. Like, for instance, that you flirt too much. You're a troublemaker, and you know how to have fun.
 <br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Best gift for Gemini:</p>As a Gemini, you're always looking for the latest technology, the best books, the newest magazine subscriptions, the hottest laptops, the most stylish phones, the most excellent video games, the cutest board games, and the best TV-series and movie DVDs.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Things Gemini gets frustrated with:</p>When people cause unnecessary problems, it's easy to feel irritated. You want to avoid them or fix the problem, but those things rarely work. You're better off ignoring the person or finding a way to improve the situation so you can move on. It doesn't mean you have to put up with people's nonsense, but it does mean you have to deal with it.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">The hardest thing for Gemini:</p>it's tough to trust people and try to calm down under specific pressures.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Things Gemini ignore:</p>You may never completely trust the person who broke your trust, but you won't let them bring you down either. You move on, determined to live your life to the fullest.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Gemini should remember two things that Gemini should keep in mind:</p>to be consistent and determined. Being consistent means keeping your promises and not letting others put you down, and being determined implies that you should not give up quickly when you face a challenge. Gemini is a sign full of surprises, so be careful not to get taken by others.
<br/><br/>
              
     
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
